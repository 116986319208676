<script>
import {api} from "@/app/services/api.service";

export default {
  name: "AnnualReturnsMoreDetails",
  props:{
      annulReturnsDetails:{}
  },
  data(){
    return{
      annulReturnsDetailss:null
    }
  },
  mounted() {
    this.filterAnnualReturns()
  },
  methods:{
    filterAnnualReturns(){
      if(this.annualReturnsInfo){
        let data = {
          upn:this.annualReturnsInfo.no,
          year:this.annualReturnsInfo.year,
        }
        this.PractitionerAnnualReturnsMoreDetails(data)
      }
    },
    async PractitionerAnnualReturnsMoreDetails(data) {
      await api().get("insolvency/practitioner-annual-returns/by-upn-and-year?upn="+data.upn+"&year="+data.year)
          .then((response) => {
            if(response && response.data){
              this.annulReturnsDetails = response.data.data
            }
          })
          .catch((error) => {
            if(error) {
              // this.loading = false;
            }
          });

    },
  },
  watch:{
    annualReturnsInfo(data){
      if(data) {
        this.filterAnnualReturns()
      }
    }
  }
}
</script>

<template>
<div>

<p class="text-sm font-bold text-[#696969]">Practitioner Profile</p>
  <hr />
<br />
  <b-row>
    <b-col cols="6">
      <div class="mb-2">
        <label class="text-gray text-xs">Year</label>
        <b-form-group>
          <b-form-input
              style="color: #505050;"
              class="form-text noFocus text-xs"
              type="text"
              :disabled="true"
              v-model="annulReturnsDetails.year"
              required>
          </b-form-input>
        </b-form-group>
      </div>

      <div class="mb-2">
        <label class="text-gray text-xs">Name</label>
        <b-form-group>
          <b-form-input
              style="color: #505050;"
              class="form-text noFocus text-xs"
              type="text"
              :disabled="true"
              v-model="annulReturnsDetails.surName"
              required>
          </b-form-input>
        </b-form-group>
      </div>

      <div class="mb-2">
        <label class="text-gray text-xs">UPN</label>
        <b-form-group>
          <b-form-input
              style="color: #505050;"
              class="form-text noFocus text-xs"
              type="text"
              :disabled="true"
              v-model="annulReturnsDetails.no"
              required>
          </b-form-input>
        </b-form-group>
      </div>


      <div class="mb-2">
        <label class="text-gray text-xs">Email</label>
        <b-form-group>
          <b-form-input
              style="color: #505050;"
              class="form-text noFocus text-xs"
              type="text"
              :disabled="true"
              v-model="annulReturnsDetails.emailAddress"
              required>
          </b-form-input>
        </b-form-group>
      </div>

      <div class="mb-2">
        <label class="text-gray text-xs">Phone</label>
        <b-form-group>
          <b-form-input
              style="color: #505050;"
            
              class="form-text noFocus text-xs"
              type="text"
              :disabled="true"
              v-model="annulReturnsDetails.phoneNumber"
              required>
          </b-form-input>
        </b-form-group>
      </div>

      <div class="mb-2">
        <label class="text-gray text-xs">Nationality</label>
        <b-form-group>
          <b-form-input
              style="color: #505050;"
            
              class="form-text noFocus text-xs"
              type="text"
              :disabled="true"
              v-model="annulReturnsDetails.nationality"
              required>
          </b-form-input>
        </b-form-group>
      </div>

      <div class="mb-2">
        <label class="text-gray text-xs">Postal Address</label>
        <b-form-group>
          <b-form-input
              style="color: #505050;"
            
              class="form-text noFocus text-xs"
              type="text"
              :disabled="true"
              v-model="annulReturnsDetails.postalAddress"
              required>
          </b-form-input>
        </b-form-group>
      </div>

    </b-col>

    <b-col cols="6">




      <b-row>
        <b-col cols="6">
          <div class="mb-2">
            <label class="text-gray text-xs">Plot</label>
            <b-form-group>
              <b-form-input
                  style="color: #505050;"
                
                  class="form-text noFocus text-xs"
                  type="text"
                  :disabled="true"
                  v-model="annulReturnsDetails.plot"
                  required>
              </b-form-input>
            </b-form-group>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="mb-2">
            <label class="text-gray text-xs">Street</label>
            <b-form-group>
              <b-form-input
                  style="color: #505050;"
                
                  class="form-text noFocus text-xs"
                  type="text"
                  :disabled="true"
                  v-model="annulReturnsDetails.street"
                  required>
              </b-form-input>
            </b-form-group>
          </div>
        </b-col>
      </b-row>




      <div class="mb-2">
        <label class="text-gray text-xs">Status</label>
        <b-form-group>
          <b-form-input
              style="color: #505050;"
            
              class="form-text noFocus text-xs"
              type="text"
              :disabled="true"
              v-model="annulReturnsDetails.status"
              required>
          </b-form-input>
        </b-form-group>
      </div>

      <b-row>
        <b-col cols="6">
          <div class="mb-2">
            <label class="text-gray text-xs">Start date</label>
            <b-form-group>
              <b-form-input
                  style="color: #505050;"
                
                  class="form-text noFocus text-xs"
                  type="text"
                  :disabled="true"
                  v-model="annulReturnsDetails.startDate"
                  required>
              </b-form-input>
            </b-form-group>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="mb-2">
            <label class="text-gray text-xs">End date</label>
            <b-form-group>
              <b-form-input
                  style="color: #505050;"
                
                  class="form-text noFocus text-xs"
                  type="text"
                  :disabled="true"
                  v-model="annulReturnsDetails.endDate"
                  required>
              </b-form-input>
            </b-form-group>
          </div>
        </b-col>
      </b-row>




      <p class="text-sm mt-2">Address</p>
      <hr />

      <b-row>
        <b-col cols="6">
          <div class="mb-2">
            <label class="text-gray text-xs">Country</label>
            <b-form-group>
              <b-form-input
                  style="color: #505050;"
                
                  class="form-text noFocus text-xs"
                  type="text"
                  :disabled="true"
                  v-model="annulReturnsDetails.physicalAddress.country.name"
                  required>
              </b-form-input>
            </b-form-group>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="mb-2">
            <label class="text-gray text-xs">Region</label>
            <b-form-group>
              <b-form-input
                  style="color: #505050;"
                
                  class="form-text noFocus text-xs"
                  type="text"
                  :disabled="true"
                  v-model="annulReturnsDetails.physicalAddress.region.name"
                  required>
              </b-form-input>
            </b-form-group>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="mb-2">
            <label class="text-gray text-xs">District</label>
            <b-form-group>
              <b-form-input
                  style="color: #505050;"
                
                  class="form-text noFocus text-xs"
                  type="text"
                  :disabled="true"
                  v-model="annulReturnsDetails.physicalAddress.district.name"
                  required>
              </b-form-input>
            </b-form-group>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="mb-2">
            <label class="text-gray text-xs">Subcounty</label>
            <b-form-group>
              <b-form-input
                  style="color: #505050;"
                
                  class="form-text noFocus text-xs"
                  type="text"
                  :disabled="true"
                  v-model="annulReturnsDetails.physicalAddress.subcounty.name"
                  required>
              </b-form-input>
            </b-form-group>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="mb-2">
            <label class="text-gray text-xs">Parish</label>
            <b-form-group>
              <b-form-input
                  style="color: #505050;"
                
                  class="form-text noFocus text-xs"
                  type="text"
                  :disabled="true"
                  v-model="annulReturnsDetails.physicalAddress.parish.name"
                  required>
              </b-form-input>
            </b-form-group>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="mb-2">
            <label class="text-gray text-xs">Village</label>
            <b-form-group>
              <b-form-input
                  style="color: #505050;"
                
                  class="form-text noFocus text-xs"
                  type="text"
                  :disabled="true"
                  v-model="annulReturnsDetails.physicalAddress.village.name"
                  required>
              </b-form-input>
            </b-form-group>
          </div>
        </b-col>
      </b-row>





    </b-col>
  </b-row>
  <br />
  <br />
  <p class="text-sm font-bold text-[#696969]">Appointments</p>
  <hr />
  <br />


    <div>
        <label class="font-bold text-md text-blue">
            # {{annulReturnsDetails.appointments.year}}
        </label>
        <table  class="min-w-full leading-normal mb-2 text-gray" style="border: solid 1px #dedede;" >
            <thead>

            <tr style="background-color: #eae8e8;">
                <td class="px-2 py-2 text-sm">#</td>
                <td class="px-2 py-2 text-sm">Company Name</td>
                <td class="px-2 py-2 text-sm">BRN</td>
                <td class="px-2 py-2 text-sm">Type</td>
                <td class="px-2 py-2 text-sm">Date</td>
                <td class="px-2 py-2 text-sm">status</td>
            </tr>

            <tr class="border border-b" v-for="(annual, indx) in annulReturnsDetails.appointments.appointments" :key="'j'+indx">
                <td class="px-2 py-2 text-sm">{{(indx + 1)}}</td>
                <td class="px-2 py-2 text-sm">{{annual.companyName ? annual.companyName : ""}}</td>
                <td class="px-2 py-2 text-sm">{{annual.companyBrn ? annual.companyBrn : ""}}</td>
                <td class="px-2 py-2 text-sm">
                    {{annual.proceedingType ? annual.proceedingType.toString().replaceAll("_", " ") : ""}}
                    {{annual.proceedingSubtype ? " [ "+annual.proceedingSubtype+" ]" : ""}}
                </td>
                <td class="px-2 py-2 text-sm">{{annual.appointmentDate ? annual.appointmentDate : ""}}</td>
                <td class="px-2 py-2 text-sm">{{annual.status ? annual.status.toString().replaceAll("_", " ") : ""}}</td>
            </tr>

            <tr class="border border-b"  v-if="annulReturnsDetails.appointments.appointments.length === 0" >
                <td colspan="6" class="px-2 py-2 text-center" >
                   Nil
                </td>
            </tr>

            </thead>
        </table>
    </div>



  <br />
  <br />
  <p class="text-sm font-bold text-[#696969]">Complaints</p>
  <hr />
  <br />


  <table  class="min-w-full leading-normal mb-2 text-gray" style="border: solid 1px #dedede;">
    <thead>

    <tr style="background-color: #eae8e8;">
      <td class="px-2 py-2 text-sm">#</td>
      <td class="px-2 py-2 text-sm">Full Name Of Complainant</td>
      <td class="px-2 py-2 text-sm">Nature Of Complaint</td>
      <td class="px-2 py-2 text-sm">status</td>
    </tr>

    <tr class="border border-b" v-for="(annual, indx) in annulReturnsDetails.complaints" :key="'j'+indx">
      <td class="px-2 py-2 text-sm">{{(indx + 1)}}</td>
      <td class="px-2 py-2 text-sm">{{annual.fullNameOfComplainant ? annual.fullNameOfComplainant : ""}}</td>
      <td class="px-2 py-2 text-sm">{{annual.natureOfComplaint ? annual.natureOfComplaint : ""}}</td>
      <td class="px-2 py-2 text-sm">{{annual. statusOfComplaint ? annual.statusOfComplaint : ""}}</td>
    </tr>
    </thead>
  </table>


  <div v-if="annulReturnsDetails.particularsOfImpediments">
  <br />
  <br />
  <p class="text-sm font-bold text-[#696969]">Particulars Of Impediments</p>
  <hr />
  <br />

  <div>
    {{annulReturnsDetails.particularsOfImpediments ? annulReturnsDetails.particularsOfImpediments : ""}}
  </div>
  </div>

</div>
</template>

<style scoped>

</style>