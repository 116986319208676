<template>
  <div class="w-full">
    <b-row>
      <b-modal id="modal-assign" hide-footer ref="assignSubmissions" title="Assign Submission">
        <Assignment :id="taskId" type="amendment" permission="can_view_bname_amendments_tasks"/>
      </b-modal>
      <b-modal ref="addPRN" title="Change PRN" size="md" hide-footer>
        <AddPRN :tracking-number="application.trackingNumber"/>
      </b-modal>
    </b-row>

    <b-row>
      <b-col cols="3">
        <b-form-input
            class="text-gray text-sm noFocus mx-2 my-2"
            style="padding:6px 5px;"
            v-model="search"
            type="text"
            placeholder="Search by Tracking No."
            required
            v-on:keyup.enter="fetch">
        </b-form-input>
      </b-col>
      <b-col cols="2">
        <b-form-group>
          <b-form-select
            v-model="statuses"
            v-on:change="fetch"
            class="form-select noFocus text-gray mx-2 my-2 text-sm">
            <b-select-option :value="null">--Filter by Status--</b-select-option>
            <b-select-option value="Draft">Draft</b-select-option>
            <b-select-option value="Pending">In Queue</b-select-option>
            <b-select-option value="Assigned">Assigned</b-select-option>
            <b-select-option value="Queried">Queried</b-select-option>
            <b-select-option value="Approved">Approved</b-select-option>
            <b-select-option value="FAILED">Payment failed</b-select-option>
            <b-select-option value="Escalated">Escalated</b-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="4"></b-col>
      <b-col cols="1">
        <b-form-select v-model="limit" class="w-full text-xs form-select border-0 paginate-details" v-on:change="fetch">
          <b-select-option value="12">12</b-select-option>
          <b-select-option value="20">20</b-select-option>
          <b-select-option value="50">50</b-select-option>
          <b-select-option value="100">100</b-select-option>
        </b-form-select>
      </b-col>
      <b-col cols="2">
        <b-row>
          <b-col class="mx-auto">
            <p class="paginate-details text-center text-xs font-bold">
              <span>{{((currentPage - 1) * limit) + 1}}</span> -
              <span v-if="limit > submissions.length ">{{pagination.totalElements}}</span>
              <span v-else>{{currentPage * limit}}</span>
              of {{pagination.totalElements}}
            </p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <div class="mx-auto">
      <b-progress value="100" v-if="loading"  variant="#007ac5" height="0.2rem" animated></b-progress>
    </div>

    <b-row>
      <b-col cols="12">
        <table class="min-w-full leading-normal">
          <thead>
          <tr class="border-b border-t border-grey">
            <th class="t-header">#</th>
            <th class="t-header">Tracking No.</th>
            <th class="t-header">BRN</th>
            <th class="t-header">Name</th>
            <th class="t-header">Type</th>
            <th class="t-header text-center">Status</th>
            <th class="t-header text-center">Assignment</th>
            <th class="t-header text-center">Action</th>
            <th class="t-header text-center">Date</th>
          </tr>
          </thead>

          <tbody>
            <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(submission, idx) in submissions" :key="idx">
              <td class="t-body">{{(currentPage - 1) * limit + idx + 1}}</td>
              <td class="t-body text-blue font-bold cursor-pointer hover:underline"
                  @click="showDetails(submission.trackingNumber)">{{submission.trackingNumber}}</td>
              <td class="t-body font-bold">{{submission.brn}}</td>
              <td class="t-body">{{submission.businessName}}</td>
              <td class="t-body text-capitalize">{{cleanEntityType(submission.filingType.toLowerCase())}}</td>
              <td class="t-body text-center">
                <p class="text-xs px-2 py-1 text-capitalize text-center" style="background: #f1f1f1">
                  <span :class="
                  (submission.status.toUpperCase() === 'DRAFT') ? 'text-gray-light' :
                  (submission.status.toUpperCase() === 'PENDING') ? 'text-gray' :
                  (submission.status.toUpperCase() === 'AWAITING_PAYMENT_CONFIRMATION')? 'text-purple':
                  (submission.status.toUpperCase() === 'PAYMENT_FAILED')? 'text-red':
                  (submission.status.toUpperCase() === 'ASSIGNED')? 'text-blue':
                  (submission.status.toUpperCase() === 'APPROVED') ? 'text-green' :
                  (submission.status.toUpperCase() === 'QUERIED') ? 'text-orange' :
                  (submission.status.toUpperCase() === 'REJECTED') ? 'text-red' : ''">
                    <span v-if="submission.status.toUpperCase() === 'AWAITING_PAYMENT_CONFIRMATION'">
                      Confirming Payment
                    </span>
                    <span v-if="submission.status.toUpperCase() === 'PENDING'">
                      In Queue
                    </span>
                    <span v-else>
                      {{ submission.status.toLowerCase().replaceAll("_", " ") }}
                    </span>
                  </span>
                </p>
              </td>
              <td class="t-body text-center">
                <p class="text-xs px-2 py-1 text-capitalize italic text-center">
                  <span v-if="submission.officer">
                    {{submission.officer.firstName}}
                    {{submission.officer.lastName}}
                  </span>
                  <span v-else-if="submission.escalation">
                    {{submission.escalation.toUser.firstName}}
                    {{submission.escalation.toUser.lastName}}
                  </span>
                  <span v-else>
                    Not assigned
                  </span>
                </p>
              </td>
              <td class="t-body text-center">
                <button
                  v-if="submission.status.toUpperCase() === 'AWAITING_PAYMENT_CONFIRMATION'"
                  class="text-xs bg-blue text-white px-2 py-1 rounded-sm" @click="move(submission)">
                  <i class="fa fa-reply mx-1"></i> Move to Queue <b-spinner v-if="loadingMoveTask" small></b-spinner>
                </button>
                <button
                  v-else-if="submission.status.toUpperCase() === 'FAILED' || submission.status.toUpperCase() === 'DRAFT'"
                  class="text-xs bg-blue text-white px-2 py-1 rounded-sm" @click="addPRN(submission)">
                  <i class="fa fa-plus mx-1"></i> Add PRN
                </button>
                <button
                  v-else-if="submission.status.toUpperCase() === 'ESCALATION' && canViewActionWithPermission('can_assign_amendment_application')"
                  class="text-xs bg-blue text-white px-2 py-1 rounded-sm" @click="assignPrompt(submission, 'Escalation')">
                  <i class="fa fa-plus mx-1"></i>Assign
                </button>
                <button
                  v-else-if="submission.status.toUpperCase() === 'ASSIGNED' "
                  class="text-xs bg-red text-white px-2 py-1 rounded-sm" @click="assignPrompt(submission)">
                  <i class="fa fa-reply mx-1"></i>Assign
                </button>
                <button
                  v-else-if="submission.status.toUpperCase() === 'PENDING' && canViewActionWithPermission('can_assign_amendment_application')"
                  class="text-xs bg-green text-white px-2 py-1 rounded-sm" @click="assignPrompt(submission)">
                  <i class="fa fa-plus mx-1"></i>Assign
                </button>
              </td>
              <td class="t-body text-center">
                {{dateTimeFormat(submission.createdAt)}}
              </td>
            </tr>
            <tr>
              <td v-if="submissions.length === 0" colspan="12" class="bg-inertgrey text-center py-1">
                <span class="text-sm">No results found</span>
              </td>
            </tr>
            <tr>
              <td v-if="hasError" colspan="12" class="bg-red text-center py-1 text-white">
                <p class="text-sm">Failed to load tasks, Try again later.</p>
                <p class="text-sm">{{errorMessage}}</p>
                <p class="text-sm">
                  <button class="bg-white text-red px-2 rounded-sm" @click="fetch">Reload <b-spinner v-if="loading" small></b-spinner></button>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
    <b-row class="mx-1" v-if="pagination.totalPages > 1">
      <b-col cols="1">
        <button @click="previous" :class="(currentPage > 1) ? 'paginate-btn' :'inert-btn'">Previous</button>
      </b-col>
      <b-col cols="1">
        <b-row>
          <b-col cols="12">
            <p class="paginate-details text-center">
              <b-badge class="bg-blue text-white">
                {{currentPage}}/{{pagination.totalPages}}
              </b-badge>
            </p>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="1" >
        <button @click="next" :class="(currentPage < pagination.totalPages) ? 'paginate-btn' :'inert-btn'">Next</button>
      </b-col>
      <b-col class="6"></b-col>
      <b-col cols="1">
        <b-form-select v-model="limit" class="w-full text-xs form-select border-0 paginate-details" v-on:change="fetch">
          <b-select-option value="12">12</b-select-option>
          <b-select-option value="20">20</b-select-option>
          <b-select-option value="50">50</b-select-option>
          <b-select-option value="100">100</b-select-option>
        </b-form-select>
      </b-col>
      <b-col cols="2">
        <b-row>
          <b-col class="mx-auto">
            <p class="paginate-details text-center text-xs font-bold">
              <span>{{((currentPage - 1) * limit) + 1}}</span> -
              <span v-if="limit > submissions.length ">{{pagination.totalElements}}</span>
              <span v-else>{{currentPage * limit}}</span>
              of {{pagination.totalElements}}
            </p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex"
import moment from "moment";
import Assignment from "@/views/home/commons/Assignment";
import AddPRN from "@/views/home/commons/AddPRN";

export default {
  name: "AllBusinessAmendmentSubmissions",
  components: { AddPRN, Assignment},
  data(){
    return{
      loading:false,
      loadingMoveTask:false,
      currentPage:1,
      limit:12,
      submissions:[],
      pagination:{
        totalElements: 0,
        totalPages:0
      },
      statuses:null,
      taskId:null,
      rows:{
        odd:"border-t border-grey hover:bg-header_grey",
        even:"border-t border-grey hover:bg-header_grey"
      },
      search:"",
      application: {},
      application_prn:null,
      hasError:false,
      errorMessage:null
    }
  },
  mounted() {
    this.fetch()
  },
  methods:{
    ...mapActions(["submitAmendmentApplicationPayment", "fetchAmendmentSubmissions", "redoTransaction"]),
    fetch(){
      let options = this.statuses
      if(this.statuses == null){
        options = "Pending,Assigned,Queried,Approved"
      }
      let data = {
        search: this.search,
        statuses: options,
        page:this.currentPage,
        limit:this.limit
      }
      this.loading = true
      this.fetchAmendmentSubmissions(data)
    },
    showDetails(trackingNumber) {
      if(trackingNumber.startsWith("800")){
        this.warningToast("Unauthorized Action", "Go to Registry to see approved Changes")
      }else{
        this.$emit("openDetails", trackingNumber)
      }
    },
    attachPrn() {
      this.loading.true
      let data = {
        "prn": this.application_prn,
        "trackingNumber": this.application.trackingNumber,
      }
      this.submitAmendmentApplicationPayment(data)
    },
    move(application){
      let data = {
        userId: JSON.parse(sessionStorage.getItem("current_user")).userId,
        prn: application.prn
      }
      this.redoTransaction(data)
    },
    next(){
      if(this.currentPage < this.pagination.totalPages){
        this.currentPage++
        this.fetch()
      }
    },
    previous(){
      if(this.currentPage > 1){
        this.currentPage--
        this.fetch()
      }else{
        this.warningToast("Observation", "No more data to show")
      }
    },
    addPRN(application){
      this.application = application
      this.$refs.addPRN.show()
    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    assignPrompt(data){
      this.taskId = data.filingApplicationId
      this.$refs.assignSubmissions.show();
    },
    cleanEntityType(name){
      let n = name.replaceAll("_", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    canViewActionWithPermission(permission) {
      return this.$can(permission, "general") === true
    }
  },
  computed:{
    ...mapGetters(["getAmendmentSubmissions",
      "getAmendmentTask", "getAmendmentTaskError", "getMoveTask", "getMoveTaskError", "getAmendmentError"])
  },
  watch:{
    getAmendmentTask() {
      this.loading = false
      this.application_prn = null;
      this.successToast("Success", "PRN Attachment Successful")
      this.$refs.addPRN.hide();
      this.fetch()
    },
    getAmendmentTaskError(response) {
      this.loading = false
      this.errorToast("Error", response.data.message)
    },
    getAmendmentSubmissions(data){
      this.loading = false
      this.submissions = data.content
      this.pagination = {
        totalPages:data.totalPages,
        totalElements:data.totalElements
      }
    },
    getAmendmentError(data){
      this.loading = false
      this.hasError = true
      this.errorMessage = data.message
      this.errorToast("Error", data.message)
    },
    getNetworkStatus(message){
      this.loading = false
      this.hasError = true
      this.errorMessage = message
      this.errorToast("Network Error", message)
    },
    getMoveTask(){
      this.loadingMoveTask = false
      this.successToast("Request Success", "Transaction retried")
      this.fetch();
    },
    getMoveTaskError(data){
      this.loadingMoveTask = false
      this.errorToast("Error", data.message)
    },
  }
}
</script>

<style scoped>
@import "../../../../../assets/styles/table.css";

.verified{
  @apply text-xs font-bold;
  color: green;
  font-style: italic;
}

.unverified{
  @apply text-xs font-bold;
  color: #b80404;
  font-style: italic;
}

.assign-btn{
  @apply px-3 py-1 bg-white border-2 font-bold border-green text-green text-xs;
}

.assign-btn:hover{
  @apply bg-green text-white;
}

.de-assign-btn{
  @apply px-3 py-1 bg-white border-2 border-red text-red font-bold text-xs;
}

.de-assign-btn:hover{
  @apply bg-red text-white;
}

.paginate-details{
  @apply px-3 py-2 bg-white my-2 text-sm text-gray;
}

.paginate-btn{
  @apply px-3 py-2 bg-white border border-white my-2 text-sm text-blue w-full;
}

.paginate-btn:hover{
  @apply border border-blue text-blue rounded-sm;
}

.inert-btn{
  @apply px-3 py-2 bg-white my-2 text-sm text-grey w-full;
}
</style>