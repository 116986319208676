<template>
  <div class="w-full">
    <b-row>
      <b-modal id="modal-assign" hide-footer ref="assignSubmissions" title="Assign Submission">
        <Assignment :id="taskId" type="insolvency"/>
      </b-modal>
    </b-row>

     <b-row  >
         <b-modal hide-footer ref="modalviewdetails" id="modal-view-details" size="xl" title="Practitioner Details">
                <PractitionerAnnualReturnsDetails v-if="task" :task="task" />
         </b-modal>
     </b-row>

      <b-row>
          <b-col cols="3">
              <b-form-input
                      class="text-gray text-sm noFocus mx-2 my-2"
                      style="padding:6px 5px;"
                      v-model="search"
                      type="text"
                      placeholder="Search by TrackingNo,Name"
                      required
                      v-on:keyup.enter="fetch">
              </b-form-input>
          </b-col>
          <b-col cols="2">
              <b-form-group>
                  <b-form-select
                          v-model="statuses"
                          v-on:change="fetch"
                          class="form-select noFocus text-gray mx-2 my-2 text-sm">
                      <b-select-option :value="null">--Filter by Status--</b-select-option>
                      <b-select-option value="DRAFT">Draft</b-select-option>
                      <b-select-option value="SUBMITTED">In Queue</b-select-option>
                      <b-select-option value="ASSIGNED">Assigned</b-select-option>
                      <b-select-option value="QUERIED">Queried</b-select-option>
                      <b-select-option value="APPROVED">Approved</b-select-option>
                      <b-select-option value="ESCALATED">Escalated</b-select-option>
                  </b-form-select>
              </b-form-group>
          </b-col>
          <b-col cols="2">
          </b-col>
          <b-col cols="2">

          </b-col>
          <b-col cols="1">
              <b-form-select v-model="limit" class="form-select w-full mt-2 text-xs paginate-details"
                             v-on:change="fetch">
                  <b-select-option value="12">12</b-select-option>
                  <b-select-option value="20">20</b-select-option>
                  <b-select-option value="50">50</b-select-option>
                  <b-select-option value="100">100</b-select-option>
              </b-form-select>
          </b-col>
          <b-col cols="2">
              <b-row>
                  <b-col class="mx-auto mt-3">
                      <p class="paginate-details text-center text-xs font-bold">
                          <span>{{ ((currentPage - 1) * limit) + 1 }}</span> -
                          <span v-if="limit > submissions.length ">{{ pagination.totalElements }}</span>
                          <span v-else>{{ currentPage * limit }}</span>
                          of {{ pagination.totalElements }}
                      </p>
                  </b-col>
              </b-row>
          </b-col>


      </b-row>
    <div class="mx-auto">
      <b-progress value="100" v-if="loading"  variant="#007ac5" height="0.2rem" animated></b-progress>
    </div>
    <b-row>
      <b-col cols="12">
        <table class="min-w-full leading-normal">
          <thead>
          <tr class="border-b border-t border-grey">
            <th class="t-header">Tracking No.</th>
            <th class="t-header">Name</th>
            <th class="t-header">UPN</th>
            <th class="t-header">Application Type</th>
            <th class="t-header">Status</th>
            <th class="t-header">Date</th>
            <th class="t-header text-center">Action</th>
          </tr>
          </thead>

          <tbody>
          <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(submission, idx) in submissions" :key="idx">
              <td class="t-body text-blue font-bold"><span style="cursor: pointer" @click="loadDetails(submission.trackingNumber )">{{ submission.trackingNumber }}</span></td>
              <td class="t-body font-bold">{{ submission.applicant.surname }} {{ submission.applicant.givenName }}
                  {{ submission.applicant.middleName }}
              </td>
            <td class="t-body text-capitalize">

                <span v-if="submission.applicant.no"> {{submission.applicant.no}}</span>
              <span>None yet</span>
            </td>
              <td class="t-body text-capitalize">{{ cleanEntityType(submission.type) }}</td>
              <td class="t-body">{{ dateTimeFormat(submission.updatedAt) }}</td>
              <td class="t-body">
                  <span :class="statusColorCode(submission.status)">{{ submission.status }}</span></td>
            <td class="t-body text-center">
              <button @click="assignPrompt(submission)" v-if="submission.status === 'PENDING'" class="assign-btn">Assign</button>
              <button v-else-if="submission.status === 'ASSIGNED'" @click="assignPrompt(submission)" class="de-assign-btn">Re-Assign</button>
              <div v-else>
                <span class="text-gray text-xs">No Action</span>
              </div>
            </td>
          </tr>
          <tr>
            <td v-if="submissions.length === 0" colspan="8" class="bg-inertgrey text-center py-1">
              <span class="text-sm">No results found</span>
            </td>
          </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
    <b-row class="mx-1" v-if="pagination.totalPages > 1">
      <b-col cols="1">
        <button @click="previous" :class="(currentPage > 1) ? 'paginate-btn' :'inert-btn'">Previous</button>
      </b-col>
      <b-col cols="1">
        <b-row>
          <b-col cols="12">
            <p class="paginate-details text-center">
              <b-badge class="bg-blue text-white">
                {{currentPage}}/{{pagination.totalPages}}
              </b-badge>
            </p>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="1" >
        <button @click="next" :class="(currentPage < pagination.totalPages) ? 'paginate-btn' :'inert-btn'">Next</button>
      </b-col>
      <b-col class="9"></b-col>
    </b-row>
  </div>
</template>

<script>
import Assignment from "@/views/home/commons/Assignment";
import {mapActions, mapGetters} from "vuex";
import moment from "moment/moment";
import {api} from "@/app/services/api.service";
import PractitionerAnnualReturnsDetails
    from "@/views/home/services/registry/practitioners/PractitionerAnnualReturnsDetails.vue";

export default {
  name: "PractitionerAnnualReturnsSubmissions",
  components: {PractitionerAnnualReturnsDetails, Assignment },
  data(){
    return{
      count:0,
      loading:true,
      currentPage:1,
      limit:6,
      submissions:[],
      pagination:{
        totalElements: 0,
        totalPages:0
      },
      taskId:null,
      rows:{
        odd:"border-t border-grey hover:bg-header_grey",
        even:"border-t border-grey bg-header_grey"
      },
        search: "",
        statuses: null,
      task:null
    }
  },
  mounted() {
    this.fetch()
  },
  methods:{
    ...mapActions(["fetchInsolvencyPractitionerTasks", "fetchRegistrars", "assignReservationTask",]),
    fetch(){
        let options = this.statuses;
        if (this.statuses === null) {
            options = "DRAFT,SUBMITTED,APPROVED,ASSIGNED,QUERIED,ESCALATED,AWAITING_PAYMENT,PAYMENT_FAILED,RESOLUTION,NOTICE,PRELIMINARY_REPORT,FINAL_NOTICE,FINAL_REPORT,REJECTED";
        }
      let data = {
        search: this.search,
          status: options,
        page:this.currentPage,
        limit:this.limit,
          type: "ANNUAL_RETURNS"
      }
      this.loading = true
      this.fetchInsolvencyPractitionerTasks(data)
    },
    next(){
      if(this.currentPage < this.pagination.totalPages){
        this.currentPage++
        this.fetch()
      }
    },
    previous(){
      if(this.currentPage > 1){
        this.currentPage--
        this.fetch()
      }else{
        this.warningToast("Observation", "No more data to show")
      }
    },
    assignPrompt(data){
      this.loading = true
      this.taskId = data.id
      this.$refs.assignSubmissions.show();
    },
    cleanEntityType(name){
      let n = name.replaceAll("_", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },loadDetails(trackingNumber){
          api().get("insolvency/practitioner-annual-returns/tracking-number?trackingNumber="+trackingNumber).then((response)=>{

             this.task = response.data.data
              this.$refs.modalviewdetails.show();

          }).catch((error)=>{
              this.errorToast("Error",error)
          })
      },
      statusColorCode(status) {
          if (status === "APPROVED") {
              return "text-green";
          } else if (status === "QUERIED") {
              return "text-orange";
          } else if (status === "ASSIGNED") {
              return "text-blue";
          } else if (status === "ESCALATED") {
              return "text-blue";
          } else if (status === "SUBMITTED") {
              return "text-gray";
          } else if (status === "DRAFT") {
              return "text-gray";
          } else if (status === "PAYMENTS") {
              return "text-purple";
          } else if (status === "PAYMENT_FAILED" || status === "REJECTED") {
              return "text-red";
          } else {
              return "text-blue";
          }
      },
  },
  computed:{
    ...mapGetters(["getInsolvencyPractitionerSubmissions", "getRegistrars", "getInsolvencyAssignmentSuccess", "getInsolvencyError"])
  },
  watch:{
    count:{
      handler(){

      }
    },
      getInsolvencyPractitionerSubmissions(data) {
      this.loading = false
      this.submissions = data.content
      this.pagination = {
        totalPages:data.totalPages,
        totalElements:data.totalElements
      }
      this.count = data.totalElements
    },
    getInsolvencyAssignmentSuccess(){
      this.successToast("Success", "Assignment has been completed")
      this.$refs.assignSubmissions.hide();
      this.fetch()
    },
    getInsolvencyError(data){
      this.loading = false
      this.errorToast("Error", JSON.stringify(data.message))
    },
    getRegistrars(){
      this.loading = false
    },

  }
}
</script>

<style scoped>
.assign-btn{
  @apply px-3 py-1 bg-white border-2 font-bold border-green text-green text-xs;
}

.assign-btn:hover{
  @apply bg-green text-white;
}

.de-assign-btn{
  @apply px-3 py-1 bg-white border-2 border-red text-red font-bold text-xs;
}

.de-assign-btn:hover{
  @apply bg-red text-white;
}
</style>