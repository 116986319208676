<script>
import {api} from "@/app/services/api.service";



export default {
  name: "PractitionerAppointments",
  components: {},
  props:{
    upn:String
  },
  data(){
    return{
      appointmentData:[],
      annualReturnsInfo:null,
      annualReturnsModal:false
    }
  },
  mounted() {
    this.fetchAppointmentsInfo()
  },
  methods:{
    async fetchAppointmentsInfo() {
        this.appointmentData = []
      await api().get("insolvency/practitioner/appointments?upn="+this.upn)
          .then((response) => {
            if(response && response.data){
              this.appointmentData = response.data
            }
          })
          .catch((error) => {
            if(error) {
              // this.loading = false;
            }
          });

    },

  }
}
</script>

<template>
<div>



  <div style="width: 80%">
    <table  v-for="(appointment, indx) in appointmentData" :key="'j'+indx" class="min-w-full leading-normal mb-1 text-gray border">
      <thead>

      <tr style="background-color: #f6f6f6;">
        <td colspan="12" class="px-2 py-2">
          <div class="flex justify-content-between">
            <div>
              <div>
                <span> {{indx +1}}. {{appointment.companyName ? appointment.companyName : ""}}</span>

              </div>
              <div class="text-xs">
                <b>TrackingNo:</b> {{appointment.trackingNo}}, <b>BRN:</b> {{appointment.brn}}, <b>Date:</b> {{appointment.appointmentDate}}
              </div>
            </div>

            <div>
              <button type="button" class="btn-sm text-white px-3 py-1 rounded-md ml-4 text-xs bg-green">{{appointment.Status ? appointment.Status : ""}}</button>
            </div>


          </div>
        </td>
      </tr>


      <!--    <tr>
            <td class="p-4">

              <b-row>
                <b-col cols="6">
                  <div class="mb-2">
                    <label class="text-gray text-xs">Name</label>
                    <b-form-group>
                      <b-form-input
                          style="color: #505050;"
                          id="companyName"
                          class="form-text noFocus text-xs"
                          type="text"
                          :disabled="true"
                          v-model="annual.name"
                          required>
                      </b-form-input>
                    </b-form-group>
                  </div>

                  <div class="mb-2">
                    <label class="text-gray text-xs">UPN</label>
                    <b-form-group>
                      <b-form-input
                          style="color: #505050;"
                          id="companyName"
                          class="form-text noFocus text-xs"
                          type="text"
                          :disabled="true"
                          v-model="annual.upn"
                          required>
                      </b-form-input>
                    </b-form-group>
                  </div>


                </b-col>

                <b-col cols="6">


                  <div class="mb-2">
                    <label class="text-gray text-xs">Status</label>
                    <b-form-group>
                      <b-form-input
                          style="color: #505050;"
                          id="companyName"
                          class="form-text noFocus text-xs"
                          type="text"
                          :disabled="true"
                          v-model="annual.status"
                          required>
                      </b-form-input>
                    </b-form-group>
                  </div>

                  <b-row>
                    <b-col cols="6">
                      <div class="mb-2">
                        <label class="text-gray text-xs">Start date</label>
                        <b-form-group>
                          <b-form-input
                              style="color: #505050;"
                              id="companyName"
                              class="form-text noFocus text-xs"
                              type="text"
                              :disabled="true"
                              v-model="annual.startDate"
                              required>
                          </b-form-input>
                        </b-form-group>
                      </div>
                    </b-col>
                    <b-col cols="6">
                      <div class="mb-2">
                        <label class="text-gray text-xs">End date</label>
                        <b-form-group>
                          <b-form-input
                              style="color: #505050;"
                              id="companyName"
                              class="form-text noFocus text-xs"
                              type="text"
                              :disabled="true"
                              v-model="annual.endDate"
                              required>
                          </b-form-input>
                        </b-form-group>
                      </div>
                    </b-col>
                  </b-row>





                </b-col>
              </b-row>




            </td>
          </tr>-->

      </thead>
        <tbody>
        <tr v-if="appointmentData.length ===0" >
            <td colspan="12" class="text-center text-sm">
                Nil
            </td>
        </tr>
        </tbody>
    </table>
  </div>




</div>
</template>

<style scoped>

</style>