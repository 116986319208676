import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/views/auth/Login/Login";
import Home from "@/views/home/Home";
import AnnualReturnsTab from "@/views/home/services/annual-returns/AnnualReturnsTab";
import Reports from "@/views/home/services/reports/Reports";
import ReservationDictionary from "@/views/home/services/reservation/dictionary/ReservationDictionary";
import Auth from "@/views/auth/Auth";
import PasswordReset from "@/views/auth/PasswordReset/PasswordReset";
import SubmissionDetails from "@/views/home/services/reservation/components/SubmissionDetails";
import abilities from "@/app/services/abilities";
import Error404 from "@/views/auth/custom/Error404";
import Error403 from "@/views/auth/custom/Error403";
import AccountProfile from "@/views/home/account/AccountProfile";
import NameReservationExtensions from "@/views/home/services/reservation/extensions/NameReservationExtensions";
import ReservationTab from "@/views/home/services/reservation/ReservationTab";
import NameReservationManagement from "@/views/home/services/reservation/submissions/NameReservationApplications";
import QuickSearch from "@/views/home/services/quick-name-search/QuickSearch";
import InsolvencyTab from "@/views/home/services/insolvency/InsolvencyTab";
import CompanyIncorporationTab from "@/views/home/services/incorporation/CompanyIncorporationTab";
import BusinessRegistrationTab from "@/views/home/services/registration/BusinessRegistrationTab";
import BusinessRegistrationManagement
  from "@/views/home/services/registration/submissions/BusinessRegistrationApplications";
import CompanyIncorporationManagement
  from "@/views/home/services/incorporation/submissions/CompanyIncorporationApplications";
import BuildingSocietyIncorporationManagement
  from "@/views/home/services/bsi_incorporation/submissions/BuildingSocietyIncorporationApplications";
import URSBRegistry from "@/views/home/services/registry/URSBRegistry";
import InsolvencyApplications
  from "@/views/home/services/insolvency/submissions/InsolvencyApplications";
import AppealedReservations from "@/views/home/services/reservation/appeals/AppealedReservations";
import ReservationEscalations from "@/views/home/services/reservation/escalations/ReservationEscalations";
import BusinessNameRegistrationEscalations
  from "@/views/home/services/registration/escalations/BusinessNameRegistrationEscalations";
import BuildingSocietyIncorporationEscalations
  from "@/views/home/services/bsi_incorporation/escalations/BuildingSocietyIncorporationEscalations";
import CompanyIncorporationEscalations
  from "@/views/home/services/incorporation/escalations/CompanyIncorporationEscalations";
import CompanyIncorporationTaskQueue
  from "@/views/home/services/incorporation/task/CompanyIncorporationTaskQueue";
import NameReservationTaskQueue from "@/views/home/services/reservation/task/NameReservationTaskQueue";
import BusinessNameRegistrationTaskQueue
  from "@/views/home/services/registration/task/BusinessNameRegistrationTaskQueue";
import BusinessRegistry from "@/views/home/services/registry/BusinessRegistry";
import DocumentRegistry from "@/views/home/services/registry/DocumentRegistry";
import BuildingSocietyIncorporationTaskQueue
  from "@/views/home/services/bsi_incorporation/task/BuildingSocietyIncorporationTaskQueue";
import LegalDocumentsTab from "@/views/home/services/legal-documents/LegalDocumentsTab";
import LegalDocumentApplications from "@/views/home/services/legal-documents/submissions/LegalDocumentApplications";
import LegalDocumentEscalations from "@/views/home/services/legal-documents/escalations/LegalDocumentEscalations";
import LegalDocumentTaskQueue from "@/views/home/services/legal-documents/task/LegalDocumentTaskQueue";
import ContinuousFilingTab from "@/views/home/services/continuous-filing/ContinuousFilingTab";
import BusinessNameFilingTab from "@/views/home/services/amendments/BusinessNameFilingTab";
import BusinessNameAmendmentsTaskQueue
  from "@/views/home/services/amendments/task/BusinessNameAmendmentsTaskQueue";
import ContinuousFilingTaskQueue from "@/views/home/services/continuous-filing/task/ContinuousFilingTaskQueue";
import ContinuousFilingApplications
  from "@/views/home/services/continuous-filing/submissions/ContinuousFilingApplications";
import BusinessNameAmendmentEscalations
  from "@/views/home/services/amendments/escalations/BusinessNameAmendmentEscalations";
import ContinuousFilingEscalations
  from "@/views/home/services/continuous-filing/escalations/ContinuousFilingEscalations";
import BusinessNameAmendmentApplications
  from "@/views/home/services/amendments/submissions/BusinessNameAmendmentApplications";
import EntityFileWrapper from "@/views/home/services/registry/business/files/EntityFileWrapper";
import LegalDocumentFileWrapper from "@/views/home/services/registry/legal-documents/LegalDocumentFileWrapper";
import InsolvencyTaskQueue from "@/views/home/services/insolvency/task-queue/InsolvencyTaskQueue";
import PractitionerRegistry from "@/views/home/services/registry/PractitionerRegistry";
import PractitionerProfileWrapper from "@/views/home/services/registry/practitioners/PractitionerProfileWrapper";
import PractitionersApplications
  from "@/views/home/services/insolvency/practitioners/submissions/PractitionersApplications";
import AnnualReturnsTaskQueue from "@/views/home/services/annual-returns/task/AnnualReturnsTaskQueue";
import AnnualReturnsApplications from "@/views/home/services/annual-returns/submissions/AnnualReturnsApplications";
import AnnualReturnsEscalations from "@/views/home/services/annual-returns/escalations/AnnualReturnsEscalations";
import InsolvencyPractitionerTaskQueue
  from "@/views/home/services/insolvency/practitioners/InsolvencyPractitionerTaskQueue";
import Tracking from "@/views/home/services/tracking/Tracking";
import PractitionerComplaints
  from "@/views/home/services/insolvency/practitioners/complaints/PractitionerComplaints.vue";
import CertificationTab from "@/views/home/services/certification/CertificationTab";
import CertificationTaskQueue from "@/views/home/services/certification/task/CertificationTaskQueue";
import CertificationApplications from "@/views/home/services/certification/submissions/CertificationApplications";
import CertificationEscalations from "@/views/home/services/certification/escalations/CertificationEscalations";
import SuccessResetPassword from "@/views/auth/PasswordReset/SuccessResetPassword.vue";
import ConfirmPassword from "@/views/auth/SetPassword/ConfirmPassword.vue";
import ConfirmPasswordSuccess from "@/views/auth/SetPassword/ConfirmPasswordSuccess.vue";
import DispensationTaskQueue from "@/views/home/services/dispensation/task/DispensationTaskQueue.vue";
import DispensationTab from "@/views/home/services/dispensation/DispensationTab.vue";
import DispensationApplications from "@/views/home/services/dispensation/submissions/DispensationApplications.vue";
import DispensationEscalations from "@/views/home/services/dispensation/escalations/DispensationEscalations.vue";
import ChargesRegistry from "@/views/home/services/registry/ChargesRegistry.vue";
import ChargeWrapper from "@/views/home/services/registry/charges/file/ChargeWrapper.vue";
import SearchesRequests from "@/views/home/services/search_reports/searches/SearchesRequests.vue";
import BeneficialOwnersRegistry from "@/views/home/services/registry/BeneficialOwnersRegistry.vue";
import RecordSearchTab from "@/views/home/services/search_reports/RecordSearchTab.vue";
import SearchReportTaskQueue from "@/views/home/services/search_reports/task/SearchReportTaskQueue.vue";
import EntityBeneficialOwnersWrapper
  from "@/views/home/services/registry/beneficial_owners/file/EntityBeneficialOwnersWrapper.vue";
import SearchReportApplications from "@/views/home/services/search_reports/submissions/SearchReportApplications.vue";
import AllCompanyDataUpdateEscalations
  from "@/views/home/services/data-update/escalations/AllCompanyDataUpdateEscalations.vue";
import CompanyDataUpdateApplications
  from "@/views/home/services/data-update/submissions/CompanyDataUpdateApplications.vue";
import CompanyDataUpdateTab from "@/views/home/services/data-update/CompanyDataUpdateTab.vue";
import BusinessNameDataUpdateTab from "@/views/home/services/data-update/BusinessNameDataUpdateTab.vue";
import BusinessNameDataUpdateApplications
  from "@/views/home/services/data-update/submissions/BusinessNameDataUpdateApplications.vue";
import AllBusinessNameDataUpdateEscalations
  from "@/views/home/services/data-update/escalations/AllBusinessNameDataUpdateEscalations.vue";
import BusinessNameDataUpdateTaskQueueLevel1
  from "@/views/home/services/data-update/task/business/levels/BusinessNameDataUpdateTaskQueueLevel1.vue";
import BusinessNameDataUpdateTaskQueueLevel2
  from "@/views/home/services/data-update/task/business/levels/BusinessNameDataUpdateTaskQueueLevel2.vue";
import CompanyDataUpdateTaskQueueLevel1
  from "@/views/home/services/data-update/task/company/levels/CompanyDataUpdateTaskQueueLevel1.vue";
import CompanyDataUpdateTaskQueueLevel2
  from "@/views/home/services/data-update/task/company/levels/CompanyDataUpdateTaskQueueLevel2.vue";
import CompanyDataUpdateTaskQueueLevel3
  from "@/views/home/services/data-update/task/company/levels/CompanyDataUpdateTaskQueueLevel3.vue";
import BusinessNameDataUpdateTaskQueueLevel3
  from "@/views/home/services/data-update/task/business/levels/BusinessNameDataUpdateTaskQueueLevel3.vue";
import BusinessNameDataUpdateTaskQueueLevel4
  from "@/views/home/services/data-update/task/business/levels/BusinessNameDataUpdateTaskQueueLevel4.vue";
import CompanyDataUpdateTaskQueueLevel4
  from "@/views/home/services/data-update/task/company/levels/CompanyDataUpdateTaskQueueLevel4.vue";
import DataCorrectionTab from "@/views/home/services/data-correction/DataCorrectionTab.vue";
import DataCorrectionRequests from "@/views/home/services/data-correction/DataCorrectionRequests.vue";
import HomeDashboard from "@/views/home/services/analytics/HomeDashboard.vue";
import DisputeResolutionTab from "@/views/home/services/dispute-resolution/DisputeResolutionTab.vue";
import DisputeResolutionTaskQueue from "@/views/home/services/dispute-resolution/task/DisputeResolutionTaskQueue.vue";
import DIsputeResolutionApplications
  from "@/views/home/services/dispute-resolution/submissions/DIsputeResolutionApplications.vue";
import DisputeResolutionEscalations
  from "@/views/home/services/dispute-resolution/escalations/DisputeResolutionEscalations.vue";
import CompanyReinstatementApplications
  from "@/views/home/services/data-update/submissions/CompanyReinstatementApplications.vue";
import DataCorrectionApplications
  from "@/views/home/services/data-correction/submissions/DataCorrectionApplications.vue";
import DataCorrectionTaskQueueLevel1
  from "@/views/home/services/data-correction/task/levels/DataCorrectionTaskQueueLevel1.vue";
import DataCorrectionTaskQueueLevel2
  from "@/views/home/services/data-correction/task/levels/DataCorrectionTaskQueueLevel2.vue";
import CourtWindingUp from "@/views/home/services/insolvency/winding-up/CourtWindingUp.vue";
import RecevershipApplications from "@/views/home/services/insolvency/submissions/RecevershipApplications.vue";
import ReceivershipTaskQueue from "@/views/home/services/insolvency/task-queue/ReceivershipTaskQueue.vue";
import InsolvRegistry from "@/views/home/services/registry/insolvency/InsolvRegistry.vue";
import AdministrationTaskQueue from "@/views/home/services/insolvency/task-queue/AdministrationTaskQueue.vue";
import AdministrationsApplications from "@/views/home/services/insolvency/submissions/AdministrationsApplications.vue";
import ForeignCessationApplications
  from "@/views/home/services/insolvency/submissions/ForeignCessationApplications.vue";
import ForeignCessationTaskQueue from "@/views/home/services/insolvency/task-queue/ForeignCessationTaskQueue.vue";
import PractitionerAnnualReturnsTaskQueue
  from "@/views/home/services/insolvency/annual-returns/PractitionerAnnualReturnsTaskQueue.vue";
import PractitionerAnnualReturnApplications
  from "@/views/home/services/insolvency/annual-returns/submissions/PractitionerAnnualReturnApplications.vue";
import AllDataCorrectionEscalations
  from "@/views/home/services/data-correction/escalations/AllDataCorrectionEscalations.vue";
import InsolvencyPetitionApplications
  from "@/views/home/services/insolvency/submissions/InsolvencyPetitionApplications.vue";
import CourtOrderPetition from "@/views/home/services/insolvency/winding-up/CourtOrderPetition.vue";
import InsolvencyPetitionTaskQueue from "@/views/home/services/dispute-resolution/task/InsolvencyPetitionTaskQueue.vue";
import PractitionerDetails from "@/views/home/services/registry/practitioners/PractitionerDetails.vue";
import BankruptcyApplications from "@/views/home/services/insolvency/submissions/BankruptcyApplications.vue";
import ProvisionalAppointment from "@/views/home/services/insolvency/bankruptcy/ProvisionalAppointment.vue";
import BsiContinuousFilingTaskQueue
  from "@/views/home/services/bsi-continuous-filing/task/BsiContinuousFilingTaskQueue.vue";
import BsiContinuousFilingApplications
  from "@/views/home/services/bsi-continuous-filing/submissions/BsiContinuousFilingApplications.vue";
import BsiContinuousFilingEscalations
  from "@/views/home/services/bsi-continuous-filing/escalations/BsiContinuousFilingEscalations.vue";
import AllInsolvencyEscalations from "@/views/home/services/insolvency/task-queue/AllInsolvencyEscalations.vue";

Vue.use(VueRouter);

let router = new VueRouter({
  mode: 'history',
  routes : [
    {
      path: '/',
      component: Auth,
      redirect: '/login',
      name:"login",
      children: [
        {
          path: 'login',
          component: Login,
          name:"login-page"
        },
        {
          path: 'reset-password',
          component: PasswordReset,
          name:"reset-password"
        },
        {
          path: 'success-reset-password',
          component: SuccessResetPassword,
          name:"success-reset-password"
        },
        {
          path: "/confirm-password-reset",
          component: ConfirmPassword,
          name: "confirm-password"
        },
        {
          path: "/success-confirm-password",
          component: ConfirmPasswordSuccess,
          name: "success-confirm-password"
        },
      ]
    },
    {
      path: "/home/profile",
      component: AccountProfile,
      name:"profile",
      meta:{
        title:"My Profile",
        resource:"general",
        action:"can_access_dashboard",
        requiresAuth:true
      }
    },
    {
      path: "/home",
      name: "Home",
      redirect:"/home/dashboard",
      component: Home,
      meta:{
        requiresAuth:true
      },
      children:[
        { path: "/home/dashboard", component: HomeDashboard, meta:{title:"", resource:"general", action:"can_access_dashboard"}},
        {
          path:"/home/reservation", component:ReservationTab, meta:{ title:"Reservation Queue",resource:"general", action:"can_access_reservations"},
          children:[
            {path:"/home/reservation/queue-task", component:NameReservationTaskQueue,  meta:{ title:"Reservation Queue",resource:"general", action:"can_view_reservation_tasks"}},
            {path:"/home/reservation/applications", component:NameReservationManagement,  meta:{ title:"Reservation Submissions",resource:"general",action:"can_view_all_reservation_submissions"},},
            {path:"/home/reservation/extensions", component:NameReservationExtensions,  meta:{ title:"Reservation Extensions",resource:"general",action:"can_view_reservation_extensions"}},
            {path:"/home/reservation/appeals", component:AppealedReservations,  meta:{ title:"Reservation Appeals",resource:"general",action:"can_view_reservation_appeals"}},
            {path:"/home/reservation/escalations", component:ReservationEscalations,  meta:{ title:"Reservation Escalations",resource:"general",action:"can_view_reservation_escalations"}},
            {path:"/home/reservation/search", component:QuickSearch,  meta:{title:"Quick Search",resource:"general",action:"can_view_name_search"}},
            {path:"/home/reservation/dictionary", component:ReservationDictionary, meta:{ title:"",resource:"general",action:"can_view_dictionaries"}},
          ]
        },
        { path:"/home/businesses", component:BusinessRegistrationTab, meta:{title:"Registration Queue",resource:"general",action:"can_access_business_names"},
          children:[
            {path:"/home/businesses/queue-task", component:BusinessNameRegistrationTaskQueue, name:"Registration Queue", meta:{title:"Registration Queue",resource:"general",action:"can_access_bname_reg_tasks"}},
            {path:"/home/businesses/applications", component:BusinessRegistrationManagement,meta:{title:"Registration Applications",resource:"general",action:"can_view_all_bname_reg_submissions"}},
            {path:"/home/businesses/escalations", component:BusinessNameRegistrationEscalations,meta:{title:"Registration Escalations",resource:"general",action:"can_view_bname_reg_escalations"}},
            {path:"/home/businesses/search", component:QuickSearch, meta:{title:"Quick Search",resource:"general",action:"can_view_name_search"}},
          ]
        },
        { path:"/home/companies", component:CompanyIncorporationTab, meta:{title:"Company Queue",resource:"general",action:"can_access_companies"},
          children:[
            {path:"/home/companies/queue-task", component:CompanyIncorporationTaskQueue,name:"Company Queue",meta:{title:"Company Queue",resource:"general",action:"can_view_incorporation_tasks"}},
            {path:"/home/companies/applications", component:CompanyIncorporationManagement,meta:{title:"Company Applications",resource:"general",action:"can_view_all_incorporation_submissions"}},
            {path:"/home/companies/escalations", component:CompanyIncorporationEscalations,meta:{title:"Company Escalations",resource:"general",action:"can_view_incorporation_escalations"}},
            {path:"/home/companies/search", component:QuickSearch, meta:{title:"Quick Search",resource:"general",action:"can_view_name_search"}},
          ]
        },
        { path:"/home/dispensations", component:DispensationTab, meta:{title:"Dispensation Queue",resource:"general",action:"can_access_companies"},
          children:[
            {path:"/home/dispensations/queue-task", component:DispensationTaskQueue,name:"Dispensation Queue",meta:{title:"Dispensation Queue",resource:"general",action:"can_view_dispensation_tasks"}},
            {path:"/home/dispensations/applications", component:DispensationApplications,meta:{title:"Dispensation Applications",resource:"general",action:"can_view_all_dispensation_submissions"}},
            {path:"/home/dispensations/escalations", component:DispensationEscalations,meta:{title:"Dispensation Escalations",resource:"general",action:"can_view_dispensation_escalations"}},
            {path:"/home/dispensations/search", component:QuickSearch, meta:{title:"Quick Search",resource:"general",action:"can_view_name_search"}},
          ]
        },
        { path:"/home/building-society", component:CompanyIncorporationTab, meta:{title:"Company Queue",resource:"general",action:"can_access_building_societies"},
          children:[
            {path:"/home/building-society/queue-task", component:BuildingSocietyIncorporationTaskQueue,name:"Building Society Queue",meta:{title:"Building Society Queue",resource:"general",action:"can_view_building_society_incorporation_tasks"}},
            {path:"/home/building-society/applications", component:BuildingSocietyIncorporationManagement,meta:{title:"Building Society Applications",resource:"general",action:"can_view_all_building_society_incorporation_submissions"}},
            {path:"/home/building-society/escalations", component:BuildingSocietyIncorporationEscalations,meta:{title:"Building Society Escalations",resource:"general",action:"can_view_building_society_incorporation_escalations"}},
            {path:"/home/building-society/search", component:QuickSearch, meta:{title:"Quick Search",resource:"general",action:"can_view_name_search"}},
          ]
        },
        { path:"/home/legal-documents", component:LegalDocumentsTab, meta:{title:"Legal Documents",resource:"general",action:"can_access_legal_documents"},
          children:[
            {path:"/home/legal-documents/queue-task", component:LegalDocumentTaskQueue,meta:{title:"Legal Documents Queue",resource:"general",action:"can_view_legal_documents_tasks"}},
            {path:"/home/legal-documents/applications", component:LegalDocumentApplications,meta:{title:"Legal Documents Applications",resource:"general",action:"can_view_all_legal_documents_submissions"}},
            {path:"/home/legal-documents/escalations", component:LegalDocumentEscalations,meta:{title:"Legal Documents Escalations",resource:"general",action:"can_view_legal_documents_escalations"}},
          ]
        },
        { path:"/home/continuous-filing", component:ContinuousFilingTab,meta:{title:"Continuous Filing",resource:"general",action:"can_access_continuous_filing"},
          children:[
            {path:"/home/continuous-filing/queue-task", component:ContinuousFilingTaskQueue,meta:{title:"Continuous Filing Queue",resource:"general",action:"can_view_continuous_filing_tasks"}},
            {path:"/home/continuous-filing/applications", component:ContinuousFilingApplications,meta:{title:"Continuous Filing Applications",resource:"general",action:"can_view_all_continuous_filing_submissions"}},
            {path:"/home/continuous-filing/escalations", component:ContinuousFilingEscalations,meta:{title:"Continuous Filing Escalations",resource:"general",action:"can_view_continuous_filing_escalations"}},
          ]
        },
        { path:"/home/bsi-continuous-filing", component:ContinuousFilingTab,meta:{title:"Building Society Continuous Filing",resource:"general",action:"can_access_bsi_continuous_filing"},
          children:[
            {path:"/home/bsi-continuous-filing/queue-task", component:BsiContinuousFilingTaskQueue,meta:{title:"Continuous Filing Queue",resource:"general",action:"can_view_bsi_continuous_filing_tasks"}},
            {path:"/home/bsi-continuous-filing/applications", component:BsiContinuousFilingApplications,meta:{title:"Continuous Filing Applications",resource:"general",action:"can_view_bsi_all_continuous_filing_submissions"}},
            {path:"/home/bsi-continuous-filing/escalations", component:BsiContinuousFilingEscalations,meta:{title:"Continuous Filing Escalations",resource:"general",action:"can_view_bsi_continuous_filing_escalations"}},
          ]
        },
        { path:"/home/business-amendments", component:BusinessNameFilingTab,meta:{title:"Business Name Amendments",resource:"general",action:"can_access_bname_amendments"},
          children:[
            {path:"/home/business-amendments/queue-task", component:BusinessNameAmendmentsTaskQueue,meta:{title:"Business Name Amendments Queue",resource:"general",action:"can_view_bname_amendments_tasks"}},
            {path:"/home/business-amendments/applications", component:BusinessNameAmendmentApplications,meta:{title:"Business Name Amendments Applications",resource:"general",action:"can_view_all_bname_amendments_submissions"}},
            {path:"/home/business-amendments/escalations", component:BusinessNameAmendmentEscalations,meta:{title:"Business Name Amendments Escalations",resource:"general",action:"can_view_bname_amendments_escalations"}},
          ]
        },
        { path:"/home/data-update-businesses", component:BusinessNameDataUpdateTab, meta:{title:"Business Data Update",resource:"general",action:"can_access_business_names_data_update"},
          children:[
            {path:"/home/data-update-businesses/queue-task/level-1", component:BusinessNameDataUpdateTaskQueueLevel1, name:"Business Name DU Queue Level 1", meta:{title:"Business Data Update Queue",resource:"general",action:"can_access_bname_data_update_tasks"}},
            {path:"/home/data-update-businesses/queue-task/level-2", component:BusinessNameDataUpdateTaskQueueLevel2, name:"Business Name DU Queue Level 2", meta:{title:"Business Data Update Queue",resource:"general",action:"can_access_bname_data_update_tasks_level2"}},
            {path:"/home/data-update-businesses/queue-task/level-3", component:BusinessNameDataUpdateTaskQueueLevel3, name:"Business Name DU Queue Level 3", meta:{title:"Business Data Update Queue",resource:"general",action:"can_access_bname_data_update_tasks_level3"}},
            {path:"/home/data-update-businesses/queue-task/level-4", component:BusinessNameDataUpdateTaskQueueLevel4, name:"Business Name DU Queue Level 4", meta:{title:"Business Data Update Queue",resource:"general",action:"can_access_bname_data_update_tasks_level4"}},
            {path:"/home/data-update-businesses/applications", component:BusinessNameDataUpdateApplications,meta:{title:"Business Data Update Applications",resource:"general",action:"can_view_all_bname_data_update_submissions"}},
            {path:"/home/data-update-businesses/escalations", component:AllBusinessNameDataUpdateEscalations,meta:{title:"Business Data Update Escalations",resource:"general",action:"can_view_bname_data_update_escalations"}},
          ]
        },
        { path:"/home/data-update-companies", component:CompanyDataUpdateTab, meta:{title:"Company Data Update",resource:"general",action:"can_access_companies_data_update"},
          children:[
            {path:"/home/data-update-companies/queue-task/level-1", component:CompanyDataUpdateTaskQueueLevel1,name:"Company DU Queue Level 1",meta:{title:"Company Data Update Queue",resource:"general",action:"can_view_company_data_update_tasks"}},
            {path:"/home/data-update-companies/queue-task/level-2", component:CompanyDataUpdateTaskQueueLevel2,name:"Company DU Queue Level 2",meta:{title:"Company Data Update Queue",resource:"general",action:"can_view_company_data_update_tasks_level2"}},
            {path:"/home/data-update-companies/queue-task/level-3", component:CompanyDataUpdateTaskQueueLevel3,name:"Company DU Queue Level 3",meta:{title:"Company Data Update Queue",resource:"general",action:"can_view_company_data_update_tasks_level3"}},
            {path:"/home/data-update-companies/queue-task/level-4", component:CompanyDataUpdateTaskQueueLevel4,name:"Company DU Queue Level 4",meta:{title:"Company Data Update Queue",resource:"general",action:"can_view_company_data_update_tasks_level4"}},
            {path:"/home/data-update-companies/applications", component:CompanyDataUpdateApplications,meta:{title:"Company Data Update Applications",resource:"general",action:"can_view_all_company_data_update_submissions"}},
            {path:"/home/data-update-companies/re-instatement-applications", component:CompanyReinstatementApplications,meta:{title:"Reinstatement  Applications",resource:"general",action:"can_view_all_company_data_update_submissions"}},
            {path:"/home/data-update-companies/escalations", component:AllCompanyDataUpdateEscalations,meta:{title:"Company Data Update Escalations",resource:"general",action:"can_view_company_data_update_escalations"}},
          ]
        },
        { path:"/home/data-correction", component:DataCorrectionTab, meta:{title:"Data Correction",resource:"general",action:"can_access_data_correction"},
          children:[
            {path:"/home/data-correction/task/level1", component:DataCorrectionTaskQueueLevel1,name:"Data Correction  Task Level 1",meta:{title:"Data Correction Applications",resource:"general",action:"can_access_leve1_data_correction"}},
            {path:"/home/data-correction/task/level2", component:DataCorrectionTaskQueueLevel2,name:"Data Correction Task level 2",meta:{title:"Data Correction Applications",resource:"general",action:"can_access_leve2_data_correction"}},
            {path:"/home/data-correction/requests", component:DataCorrectionRequests,name:"Data Correction Applications",meta:{title:"Data Correction Applications",resource:"general",action:"can_view_data_correction_requests"}},
            {path:"/home/data-correction/applications", component:DataCorrectionApplications,name:"Data Correction Applications",meta:{title:"Data Correction Applications",resource:"general",action:"can_view_all_data_correction_applications"}},
            {path:"/home/data-correction/escalations", component:AllDataCorrectionEscalations,name:"Data Correction Escalations",meta:{title:"Data Correction Escalations",resource:"general",action:"can_access_data_correction_escalations"}},
          ]
        },
        { path:"/home/annual-returns", component:AnnualReturnsTab, meta:{title:"",resource:"general",action:"can_access_annual_returns"},
          children:[
            {path:"/home/annual-returns/queue-task", component:AnnualReturnsTaskQueue, name:"annual_returns_queue",meta:{title:"Annual Returns Queue",resource:"general",action:"can_view_annual_returns_tasks"}},
            {path:"/home/annual-returns/applications", component:AnnualReturnsApplications, name:"annual_returns_applications",meta:{title:"Annual Returns Applications",resource:"general",action:"can_view_all_annual_returns_submissions"}},
            {path:"/home/annual-returns/escalations", component:AnnualReturnsEscalations,name:"annual_returns_escalations",meta:{title:"Annual Returns Escalations",resource:"general",action:"can_view_annual_returns_escalations"}},
          ]
        },
        { path:"/home/record-search", component:RecordSearchTab, meta:{title:"",resource:"general",action:"can_access_searches"},
          children:[
            {path:"/home/record-search/task-queue", component:SearchReportTaskQueue, name:"search_report_queue",meta:{title:"Search Report Task",resource:"general",action:"can_view_searches"}},
            {path:"/home/record-search/searches", component:SearchesRequests,name:"search_report_submissions",meta:{title:"Search Submissions",resource:"general",action:"can_view_searches"}},
            {path:"/home/record-search/search-report-requests", component:SearchReportApplications,name:"search_report_requests",meta:{title:"Search Report Requests",resource:"general",action:"can_view_search_report_request"}},
          ]
        },
        { path:"/home/certifications", component:CertificationTab, meta:{title:"",resource:"general",action:"can_access_certification"},
          children:[
            {path:"/home/certifications/queue-task", component:CertificationTaskQueue, name:"Certification Queue",meta:{title:"Certification Queue",resource:"general",action:"can_view_certification_tasks"}},
            {path:"/home/certifications/applications", component:CertificationApplications, meta:{title:"Certification Applications",resource:"general",action:"can_view_all_certification_submissions"}},
            {path:"/home/certifications/escalations", component:CertificationEscalations,meta:{title:"Certification Escalations",resource:"general",action:"can_view_certification_escalations"}},
          ]
        },
        { path:"/home/disputes", component:DisputeResolutionTab, meta:{title:"",resource:"general",action:"can_access_disputes"},
          children:[
            {path:"/home/disputes/queue-task", component:DisputeResolutionTaskQueue, name:"dispute_queue",meta:{title:"Dispute Queue",resource:"general",action:"can_view_dispute_tasks"}},
            {path:"/home/disputes/official-account-queue-task", component:DisputeResolutionTaskQueue, name:"official-account-dispute_queue",meta:{title:"Dispute Queue",resource:"general",action:"can_view_official_account_tasks"}},
            {path:"/home/disputes/applications", component:DIsputeResolutionApplications, name:"dispute_apps",meta:{title:"Dispute Applications",resource:"general",action:"can_view_all_dispute_submissions"}},
            {path:"/home/disputes/escalations", component:DisputeResolutionEscalations,name:"dispute_escalations",meta:{title:"Dispute  Escalations",resource:"general",action:"can_view_dispute_escalations"}},
          ]
        },
        { path:"/home/insolvency", component:InsolvencyTab, meta:{title:"Insolvency",resource:"general",action:"can_access_insolvency"},
          children:[
            {path:"/home/insolvency/queue-task", component:InsolvencyTaskQueue, name:"winding-up-queue",meta:{title:"Winding up Queue",resource:"general",action:"can_view_insolvency_tasks"}},
            {path:"/home/insolvency/receivership-queue-task", component:ReceivershipTaskQueue, name:"receivership-queue",meta:{title:"Receivership Applications Queue",resource:"general",action:"can_view_insolvency_tasks"}},
            {path:"/home/insolvency/administration-queue-task", component:AdministrationTaskQueue, name:"administration-queue",meta:{title:"Administration Applications Queue",resource:"general",action:"can_view_insolvency_tasks"}},
            {path:"/home/insolvency/cessation-queue-task", component:ForeignCessationTaskQueue, name:"cessation-queue",meta:{title:"Cessation Applications Queue",resource:"general",action:"can_view_de_registration_tasks"}},
            {path:"/home/insolvency/winding-up-applications", component:InsolvencyApplications, name:"insolvency-applications",meta:{title:"Insolvency Applications",resource:"general",action:"can_access_view_all_insolvency_submissions"}},
            {path:"/home/insolvency/receivership-applications", component:RecevershipApplications, name:"receivership-applications",meta:{title:"Receivership Applications",resource:"general",action:"can_view_all_receivership_submissions"}},
            {path:"/home/insolvency/administration-applications", component:AdministrationsApplications, name:"administration-applications",meta:{title:"Administration Applications",resource:"general",action:"can_view_all_administration_submissions"}},
            {path:"/home/insolvency/cessation-applications", component:ForeignCessationApplications, name:"cessation-applications",meta:{title:"Cessation Applications",resource:"general",action:"can_view_all_de_registration_submissions"}},
            {path:"/home/insolvency/escalations", component:AllInsolvencyEscalations, name:"insolvency-escalations",meta:{title:"Insolvency Escalations",resource:"general",action:"can_view_insolvency_submissions"}},
             {path:"/home/insolvency/court-winding-up", component:CourtWindingUp, name:"court-order-winding-up",meta:{title:"Court Winding Up",resource:"general",action:"can_initiate_court_winding_up"}},
             {path:"/home/insolvency/provisional-appointment", component:ProvisionalAppointment, name:"provisional-appointment",meta:{title:"Provisional Appointment",resource:"general",action:"can_view_bankruptcy_task"}},
             {path:"/home/insolvency/court-petition", component:CourtOrderPetition, name:"court-order-petition",meta:{title:"Court Order Petition",resource:"general",action:"can_initiate_court_winding_up"}},
            {path:"/home/insolvency/petitions-queue-task", component:InsolvencyPetitionTaskQueue, name:"petitions_queue",meta:{title:"Verification Application  Queue",resource:"general",action:"can_view_insolvency_petition_task"}},
            {path:"/home/insolvency/petition-applications", component:InsolvencyPetitionApplications, name:"petition_apps",meta:{title:"Petition Applications",resource:"general",action:"can_view_insolvency_petitions"}},


          ]},
        { path:"/home/practitioners", component:InsolvencyTab, meta:{title:"Insolvency",resource:"general",action:"can_access_insolvency"},
          children:[
                {path:"/home/practitioners/practitioner-task", component:InsolvencyPractitionerTaskQueue, meta:{title:"Practitioner Registration Queue",resource:"general",action:"can_view_insolvency_practitioner_tasks"}},
            {path:"/home/practitioners/practitioner-renewal-task", component:InsolvencyPractitionerTaskQueue, meta:{title:"Practitioner Renewal Queue",resource:"general",action:"can_view_insolvency_practitioner_tasks"}},
            {path:"/home/practitioners/practitioner-applications", component:PractitionersApplications, meta:{title:"Practitioner Applications",resource:"general",action:"can_view_all_insolvency_practitioner_submissions"}},
            {path:"/home/practitioners/escalations", component:AllInsolvencyEscalations, meta:{title:"Practitioner Escalations",resource:"general",action:"can_view_all_insolvency_practitioner_submissions"}},
                {path:"/home/practitioners/practitioner-complaints", component:PractitionerComplaints, meta:{title:"Practitioner Complaints",resource:"general",action:"can_view_all_insolvency_practitioner_complaints"}},
                {path:"/home/practitioners/practitioner-profile/:id", component:PractitionerDetails, name:"practitioner_profile", meta:{title:"Practitioner Details",resource:"general",action:"can_view_practitioner_registry"}},
          ]},

        { path:"/home/bankruptcy", component:InsolvencyTab, meta:{title:"Insolvency",resource:"general",action:"can_access_insolvency"},
          children:[
            {path:"/home/bankruptcy/task", component:InsolvencyTaskQueue, name:"bankruptcy_queue", meta:{title:"Bankruptcy  Queue",resource:"general",action:"can_view_practitioner_returns_tasks"}},
            {path:"/home/bankruptcy/applications", component:BankruptcyApplications, name:"bankruptcy_apps",meta:{title:"Bankruptcy Applications",resource:"general",action:"can_view_bankruptcy_applications"}},
                      ]},

        { path:"/home/insolvency-annual-returns", component:InsolvencyTab, meta:{title:"Insolvency",resource:"general",action:"can_access_insolvency"},
          children:[
            {path:"/home/insolvency-annual-returns/task", component:PractitionerAnnualReturnsTaskQueue, meta:{title:"Practitioner Annual returns Queue",resource:"general",action:"can_view_practitioner_returns_tasks"}},
              {path:"/home/insolvency-annual-returns/applications", component:PractitionerAnnualReturnApplications, meta:{title:"Practitioner Annual returns Applications",resource:"general",action:"can_view_all_practitioner_returns_submissions"}},
            {path:"/home/insolvency-annual-returns/escalations", component:PractitionersApplications, meta:{title:"Practitioner Escalations",resource:"general",action:"can_view_practitioner_returns_escalations"}},
          ]},

        { path:"/home/registry", component:URSBRegistry, meta:{title:"URSB Registry", resource:"general",action:"can_access_registry"},
          children:[
            {
              path:"/home/registry/business",
              component:BusinessRegistry,
              name:"business-registry",
              meta:{title:"Business Registry",resource:"general",action:"can_view_business_registry"},
            },
            {
              path:"/home/registry/beneficial-owners",
              component:BeneficialOwnersRegistry,
              name:"beneficial-owners-registry",
              meta:{title:"Beneficial Owner Registry",resource:"general",action:"can_view_beneficial_owner_registry"},
            },
            {
              path:"/home/registry/documents",
              component:DocumentRegistry,
              name:"document-registry",
              meta:{title:"Document Registry",resource:"general",action:"can_view_document_registry"}
            },
            {
              path:"/home/registry/charges",
              component:ChargesRegistry,
              name:"charges-registry",
              meta:{title:"Charges Registry",resource:"general",action:"can_view_charges_registry"}
            },
            {
              path:"/home/registry/practitioners",
              component:PractitionerRegistry,
              name:"practitioners-registry",
              meta:{title:"Practitioner Registry",resource:"general",action:"can_view_practitioner_registry"}
            },
            {
              path:"/home/registry/insolvency",
              component:InsolvRegistry,
              name:"insolvency-registry",
              meta:{title:"Practitioner Registry",resource:"general",action:"can_view_practitioner_registry"}
            },
            {
              path:"/home/registry/business/file-viewer/:id",
              component:EntityFileWrapper,
              name:"file-viewer",
              meta:{title:"File - URSB Registry",resource:"general",action:"can_view_business_registry_file"}
            },
            {
              path:"/home/registry/charge/file-viewer/:id",
              component:ChargeWrapper,
              name:"charge-viewer",
              meta:{title:"File - URSB Registry",resource:"general",action:"can_view_charges_registry_file"}
            },
            {
              path:"/home/registry/beneficial-owners/file-viewer/:id",
              component:EntityBeneficialOwnersWrapper,
              name:"beneficial-owners-viewer",
              meta:{title:"File - URSB Registry",resource:"general",action:"can_view_business_registry_file"}
            },
            {
              path:"/home/registry/documents/document-viewer/:id",
              component:LegalDocumentFileWrapper,
              name:"document-viewer",
              meta:{title:"Document - URSB Registry",resource:"general",action:"can_view_document_registry_file"}
            },
            {
              path:"/home/registry/practitioners/practitioner-viewer/:id",
              component:PractitionerProfileWrapper,
              name:"practitioner-viewer",
              meta:{title:"Practitioner - URSB Registry",resource:"general",action:"can_view_practitioner_registry"}
            },
          ]
        },
        { path:"/home/tracking", component:Tracking, meta:{title:"Tracking Applications", resource:"general", action:"can_access_tracking"}},
        { path:"/home/reports", component:Reports, meta:{title:"Reports",resource:"general", action:"can_access_reports"}},
      ]
    },
    {
      path: "/submission/reservation/:id",
      component: SubmissionDetails
    },
    {
      path: "/error-404",
      component: Error404,
      name: "error-404",
      meta: {
        title: "Error-404",
      },
    },
    {
      path: "/forbidden",
      name: "forbidden",
      component: Error403,
      meta: {
        title: "Unauthorized",
        requiresAuth: true,
        resource: "forbidden",
        action:"read"
      },
    },
    {
      path: "*",
      redirect: {name: "error-404"},
    },
  ]
});

let current_user = sessionStorage.getItem("current_user");

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (current_user === null) {
      sessionStorage.clear();

      next({
        name: "login",
        params: {nextUrl: to.fullPath},
      });
    } else {

      const canNavigate = to.matched.some((route) => {
        return abilities.can(route.meta.action, route.meta.resource);
      });

      if (!canNavigate) {
        abilities.update([{action: "read", subject: "forbidden"}]);
        return next({
          name: "forbidden",
        });
      }

      let user = JSON.parse(current_user);
      if (user !== null) {
        next();
      } else {
        next({name: "Home"});
      }
    }
  } else {
    if (current_user === null) {
      next();
    } else {
      next({
        name: "Home",
      });
    }
  }
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || "URSB - OBRS BackOffice";
  });
});

export default router