<script>
import {api} from "@/app/services/api.service";
import AnnualReturnsMoreDetails from "@/views/home/services/registry/practitioners/AnnualReturnsMoreDetails.vue";
import PRNViewer from "@/views/home/commons/PRNViewer.vue";
import InsolvencyFormFiles from "@/views/home/services/insolvency/task-queue/InsolvencyFormFiles.vue";
import InsolvencyMessages from "@/views/home/services/insolvency/task-queue/InsolvencyMessages.vue";


export default {
  name: "PractitionerAnnualReturnsDetails",
  components: {InsolvencyMessages, InsolvencyFormFiles, PRNViewer, AnnualReturnsMoreDetails},
  props:{
      task : Object

  },
  data(){
    return{
      annulReturns:null,
      annualReturnsInfo:null,
      annualReturnsModal:false,
        currentRoute: "forms",
    }
  },
  mounted() {
    this.fetchPractitionerAnnualReturnsInfo()
  },
  methods:{
    async fetchPractitionerAnnualReturnsInfo() {
      await api().get("insolvency/practitioner-annual-returns/by-upn?upn="+this.upn)
          .then((response) => {
            if(response && response.data){
              this.annulReturns = response.data.content
            }
          })
          .catch((error) => {
            if(error) {
              // this.loading = false;
            }
          });

    },
    showMoreAnnualReturnsDetails(profile){
        let dataProp = {

        }

        // find object in task.appointments where annual.year === appointment.year
        // this.annualReturnsInfo =
        dataProp.appointments = this.task.appointments.find(appointment => appointment.year === profile.year)
        this.annualReturnsInfo = {...profile,appointments:dataProp.appointments}
      this.annualReturnsModal = true
    },
      select(route) {
          this.currentRoute = route
      },
  }
}
</script>

<template>
<div>


    <b-overlay
            :show="loading"
            rounded="sm"
            spinner-variant="primary"
            opacity="0.6">
        <b-row>
            <b-col cols="12">
                <b-row>
                    <b-col cols="12">
                        <div class="flex h-full" style="cursor: pointer;">
                            <div :class="[(currentRoute === 'forms') ? 'menu-item-active' : 'menu-item']" @click="select('forms')">
                                <div class="flex mt-6">
                                    <div style="font-size: 14px">Application Information</div>
                                </div>
                            </div>
                            <div :class="[(currentRoute === 'attachments') ? 'menu-item-active' : 'menu-item']" @click="select('attachments')">
                                <div class="flex mt-6">
                                    <div style="font-size: 14px">Attachments</div>
                                </div>
                            </div>
                            <div :class="[(currentRoute === 'messages') ? 'menu-item-active' : 'menu-item']" @click="select('messages')">
                                <div class="flex mt-6">
                                    <div style="font-size: 14px">Messages</div>
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="11" class="mx-auto">

                        <div v-if="currentRoute === 'forms'">

                            <b-row class="my-2" v-if="task.years.length>0">
                                <b-col>
                                    <b-badge class="bg-green mx-1">Incoming Filings <i class="fa fa-arrow-right text-white"></i></b-badge>
                                    <b-badge class="bg-blue mx-1" v-for="(file,idx) in task.years" :key="idx">
                                        {{file}}
                                    </b-badge>
                                </b-col>
                            </b-row>


                            <b-row class="my-2" v-if="task.payment">
                                <b-col  cols="11" >
                                    <PRNViewer  :tracking-number="task.trackingNumber"/>
                                </b-col>

                            </b-row>
                            <b-row class="my-2" >
                                <b-col  cols="11" >
                                <table  v-for="(annual, indx) in task.profiles" :key="'j'+indx" class="min-w-full leading-normal mb-1 text-gray border">
                                    <thead>

                                    <tr style="background-color: #f6f6f6;">
                                        <td colspan="12" class="px-2 py-2">
                                            <div class="flex justify-content-between">
                                                <div>
                                                    <div>
                                                        <span>Annual returns for the Year {{annual.year ? annual.year : ""}}</span>
<!--                                                        <span class="text-white px-3 rounded-md ml-4 text-sm" :class="annual.status === 'FILING' ? 'bg-green':'bg-red'">-->
<!--                                                            Appointments  // count of appointments in task.appointments for year -->
<!--                                                        </span>-->
                                                    </div>
                                                    <div class="text-xs">
                                                        <b>Name:</b> {{annual.surName}} {{annual.givenName}}, <b>Start Date:</b> {{annual.startDate}}, <b>End Date:</b> {{annual.endDate}}
                                                    </div>
                                                </div>

                                                <div>
                                                    <button
                                                            type="button"
                                                            class="bg-blue text-white text-xs noFocus"
                                                            @click="showMoreAnnualReturnsDetails(annual)">
                                 <span class="mx-3 py-0">
                                  <i class="fa fa-bars mr-1"></i> More Details
                                 </span>
                                                    </button>
                                                </div>


                                            </div>
                                        </td>
                                    </tr>

                                    <!--    <tr>
                                          <td class="p-4">

                                            <b-row>
                                              <b-col cols="6">
                                                <div class="mb-2">
                                                  <label class="text-gray text-xs">Name</label>
                                                  <b-form-group>
                                                    <b-form-input
                                                        style="color: #505050;"
                                                        id="companyName"
                                                        class="form-text noFocus text-xs"
                                                        type="text"
                                                        :disabled="true"
                                                        v-model="annual.name"
                                                        required>
                                                    </b-form-input>
                                                  </b-form-group>
                                                </div>

                                                <div class="mb-2">
                                                  <label class="text-gray text-xs">UPN</label>
                                                  <b-form-group>
                                                    <b-form-input
                                                        style="color: #505050;"
                                                        id="companyName"
                                                        class="form-text noFocus text-xs"
                                                        type="text"
                                                        :disabled="true"
                                                        v-model="annual.upn"
                                                        required>
                                                    </b-form-input>
                                                  </b-form-group>
                                                </div>


                                              </b-col>

                                              <b-col cols="6">


                                                <div class="mb-2">
                                                  <label class="text-gray text-xs">Status</label>
                                                  <b-form-group>
                                                    <b-form-input
                                                        style="color: #505050;"
                                                        id="companyName"
                                                        class="form-text noFocus text-xs"
                                                        type="text"
                                                        :disabled="true"
                                                        v-model="annual.status"
                                                        required>
                                                    </b-form-input>
                                                  </b-form-group>
                                                </div>

                                                <b-row>
                                                  <b-col cols="6">
                                                    <div class="mb-2">
                                                      <label class="text-gray text-xs">Start date</label>
                                                      <b-form-group>
                                                        <b-form-input
                                                            style="color: #505050;"
                                                            id="companyName"
                                                            class="form-text noFocus text-xs"
                                                            type="text"
                                                            :disabled="true"
                                                            v-model="annual.startDate"
                                                            required>
                                                        </b-form-input>
                                                      </b-form-group>
                                                    </div>
                                                  </b-col>
                                                  <b-col cols="6">
                                                    <div class="mb-2">
                                                      <label class="text-gray text-xs">End date</label>
                                                      <b-form-group>
                                                        <b-form-input
                                                            style="color: #505050;"
                                                            id="companyName"
                                                            class="form-text noFocus text-xs"
                                                            type="text"
                                                            :disabled="true"
                                                            v-model="annual.endDate"
                                                            required>
                                                        </b-form-input>
                                                      </b-form-group>
                                                    </div>
                                                  </b-col>
                                                </b-row>





                                              </b-col>
                                            </b-row>




                                          </td>
                                        </tr>-->

                                    </thead>
                                </table>
                                </b-col>
                            </b-row>
                            <b-row  class="my-1">
                                <b-col cols="11" >
                                    <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                                        <thead>
                                        <tr class="bg-blue-pale">
                                            <td colspan="12" class="px-2 py-2">Applicant Details</td>
                                        </tr>
                                        </thead>
                                        <tr>
                                            <th class="px-2 py-1">Name</th>
                                            <th class="px-2 py-1">Registration Number </th>
                                            <th class="px-2 py-1">Email</th>
                                            <th class="px-2 py-1">Phone</th>
                                        </tr>
                                        <tbody>
                                        <tr style="border: solid 1px #dedede;">
                                            <td class="px-2 py-1 text-capitalize">{{ task.applicant.surname }}  {{ task.applicant.givenName }}</td>
                                            <td class="px-2 py-1 text-capitalize">{{ task.applicant.nin }}</td>
                                            <td class="px-2 py-1 text-capitalize">
                                                {{ task.applicant.email }}
                                            </td>
                                            <td class="px-2 py-1 text-capitalize">
                                                {{ task.applicant.phoneNumber }}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </b-col>
                            </b-row>
                        </div>
                        <InsolvencyFormFiles v-if="currentRoute === 'attachments'" :task="task"/>
                        <InsolvencyMessages v-if="currentRoute === 'messages'" :task="task" mode="THREAD"/>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-overlay>

  <b-modal v-model="annualReturnsModal" size="xl" hide-footer title="Annual Returns Details">
    <AnnualReturnsMoreDetails :annulReturnsDetails="annualReturnsInfo"></AnnualReturnsMoreDetails>
  </b-modal>







</div>
</template>

<style scoped>

</style>