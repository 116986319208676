<template>
    <b-row >
        <b-col>

            <b-overlay
                    :show="loading"
                    rounded="sm"
                    spinner-variant="primary"
                    opacity="0.6">
                <b-row>
                    <b-col cols="12">
                        <b-row v-if="task.payment">
                            <b-col cols="12" v-if="task.dataUpdate" >
                                <label class="text-blue border-1 rounded-b-sm w-full my-2 text-center ">
                                    <span class="text-center">Payment Not Applicable</span>
                                </label>
                            </b-col>
                            <b-col v-else cols="12">
                                <PRNViewer  :tracking-number="task.trackingNumber"/>
                            </b-col>

                        </b-row>

                        <b-row>
                            <b-col cols="12">
                                <div class="flex h-full" style="cursor: pointer;">
                                    <div :class="[(currentRoute === 'forms') ? 'menu-item-active' : 'menu-item']" @click="select('forms')">
                                        <div class="flex mt-6">
                                            <div style="font-size: 14px">Application Information</div>
                                        </div>
                                    </div>
                                    <div :class="[(currentRoute === 'attachments') ? 'menu-item-active' : 'menu-item']" @click="select('attachments')">
                                        <div class="flex mt-6">
                                            <div style="font-size: 14px">Attachments</div>
                                        </div>
                                    </div>
                                    <div :class="[(currentRoute === 'messages') ? 'menu-item-active' : 'menu-item']" @click="select('messages')">
                                        <div class="flex mt-6">
                                            <div style="font-size: 14px">Messages</div>
                                        </div>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="11" class="mx-auto">
                                <PractitionerDetails v-if="currentRoute === 'forms'" :draft="task"/>
                                <InsolvencyFormFiles v-if="currentRoute === 'attachments'" :task="task"/>
                                <InsolvencyMessages v-if="currentRoute === 'messages'" :task="task" mode="THREAD"/>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-overlay>
        </b-col>
    </b-row>
</template>

<script>
import {  mapGetters } from "vuex";
import InsolvencyFormFiles from "@/views/home/services/insolvency/task-queue/InsolvencyFormFiles.vue";
import InsolvencyMessages from "@/views/home/services/insolvency/task-queue/InsolvencyMessages.vue";
import PractitionerDetails from "@/views/home/services/registry/practitioners/PractitionerDetails.vue";
import PRNViewer from "@/views/home/commons/PRNViewer.vue";
export default {
    name: "PractitionerApplicationDetails",
    components: {PRNViewer, PractitionerDetails, InsolvencyMessages, InsolvencyFormFiles,},
    props: {
        task :{}
    },
    data() {
        return {
            margin: "2px",
            currentRoute: "forms",
            loading: false,

        }
    },
    methods: {
        select(route) {
            this.currentRoute = route
        },


    },
    computed: {
        ...mapGetters([]),
    },

    mounted() {

    },

    watch: {

    },


};
</script>

<style scoped>

th, td, p{
    font-size: 12px;
}

</style>